import { isAndroid } from 'packages/platform-detector';

declare global {
  interface Window {
    PushNotification: PushNotification;
  }
}

export interface Channel {
  /**
   * The id of the channel. Must be unique per package. The value may be truncated if it is too long.
   */
  id: string;
  /**
   * The user visible name of the channel. The recommended maximum length is 40 characters; the value may be truncated if it is too long.
   */
  description: string;
  /**
   * The importance of the channel. This controls how interruptive notifications posted to this channel are. The importance property goes from 1 = Lowest, 2 = Low, 3 = Normal, 4 = High and 5 = Highest.
   */
  importance: number;
  /**
   * The name of the sound file to be played upon receipt of the notification in this channel. Empty string to disable sound. Cannot be changed after channel is created.
   */
  sound?: string;
  /**
   * Boolean sets whether notification posted to this channel should vibrate. Array sets custom vibration pattern. Example - vibration: [2000, 1000, 500, 500]. Cannot be changed after channel is created.
   */
  vibration?: boolean | number[];
  /**
   * Sets whether notifications posted to this channel appear on the lockscreen or not, and if so, whether they appear in a redacted form. 0 = Private, 1 = Public, -1 = Secret.
   */
  visibility?: number;
}

export interface ChannelList {
  marketing: Channel;
}

export interface PushNotification {
  init(options: InitOptions): PushNotification;
  /**
   * Checks whether the push notification permission has been granted.
   * @param successCallback Is called when the api successfully retrieves the details on the permission.
   * @param errorCallback	Is called when the api fails to retrieve the details on the permission.
   */
  hasPermission(
    successCallback: (data: { isEnabled: boolean }) => void,
    errorCallback: () => void
  ): void;
  /**
   * The event registration will be triggered on each successful registration with the 3rd party push service.
   * @param event
   * @param callback
   */
  on(
    event: 'registration',
    callback: (response: RegistrationEventResponse) => any
  ): void;
  /**
   * The event error will trigger when an internal error occurs and the cache is aborted.
   * @param event
   * @param callback
   */
  on(event: 'error', callback: (response: Error) => any): void;
  on(
    event: 'notification',
    callback: (response: NotificationEventAdditionalData) => any
  ): void;
  createChannel(
    successCallback: () => void,
    errorCallback: () => void,
    channel: Channel
  ): void;
}

/**
 * platform specific initialization options.
 */
export interface InitOptions {
  /**
   * iOS specific initialization options.
   */
  ios?: {
    /**
     * If true|"true" the device sets the badge number on receipt of notification.
     * Default is false|"false".
     * Note: the value you set this option to the first time you call the init method will be how the application always acts.
     * Once this is set programmatically in the init method it can only be changed manually by the user in Settings>Notifications>App Name.
     * This is normal iOS behaviour.
     */
    badge?: boolean | string;
    /**
     * If true|"true" the device plays a sound on receipt of notification.
     * Default is false|"false".
     * Note: the value you set this option to the first time you call the init method will be how the application always acts.
     * Once this is set programmatically in the init method it can only be changed manually by the user in Settings>Notifications>App Name.
     * This is normal iOS behaviour.
     */
    sound?: boolean | string;
    /**
     * If true|"true" the device shows an alert on receipt of notification.
     * Default is false|"false".
     * Note: the value you set this option to the first time you call the init method will be how the application always acts.
     * Once this is set programmatically in the init method it can only be changed manually by the user in Settings>Notifications>App Name.
     * This is normal iOS behaviour.
     */
    alert?: boolean | string;
    /**
     * If true the icon badge will be cleared on init and before push messages are processed. Default is false.
     */
    clearBadge?: boolean;
  };
  android?: {
    /**
     * If true it plays the sound specified in the push data or the default system sound. Default is true.
     */
    sound?: boolean | string;
    /**
     * If true the icon badge will be cleared on init and before push messages are processed. Default is false.
     */
    clearBadge?: boolean;
  };
  config?: {
    themeColor: string;
  };
}

export interface RegistrationEventResponse {
  /**
   * The registration ID provided by the 3rd party remote push service.
   */
  registrationId: string;
}

export interface NotificationEventAdditionalData {
  [name: string]: any;

  /**
   * Whether the notification was received while the app was in the foreground
   */
  foreground?: boolean;
  /**
   * Will be true if the application is started by clicking on the push notification, false if the app is already started. (Android/iOS only)
   */
  coldstart?: boolean;
  collapse_key?: string;
  from?: string;
  notId?: string;
}

export interface PushNotificationPlugin {
  hasPermission(): Promise<boolean>;
  init(): void;
  getDeviceToken(): Promise<string>;
  registerNotificationListener(): void;
}

export class CordovaPushNotificationPlugin implements PushNotificationPlugin {
  private pnInstance: PushNotification | undefined;

  constructor(
    private readonly pushPlugin: PushNotification,
    private getToken: () => string | undefined,
    private themeColor: string
  ) {}

  hasPermission(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.pushPlugin.hasPermission(
        (data) => resolve(data.isEnabled),
        () => reject()
      );
    });
  }

  init(): void {
    const options: InitOptions = {
      ios: {
        alert: true,
        badge: true,
        sound: true,
        clearBadge: true
      },
      android: {
        sound: true,
        clearBadge: true
      },
      config: {
        themeColor: this.themeColor
      }
    };
    this.pnInstance = this.pushPlugin.init(options);
  }

  getDeviceToken(): Promise<string> {
    if (!this.pnInstance) this.init();
    return new Promise((resolve, reject) => {
      {
        this.pnInstance?.on('registration', (data) => {
          resolve(data.registrationId);
        });
        this.pnInstance?.on('error', (error) => {
          window.console.log('Push Notification ', error);
          reject(null);
        });
      }
    });
  }

  registerNotificationListener(): void {
    const deviceToken = this.getToken();
    if (!deviceToken) return;
    if (!this.pnInstance) this.init();
    let url = '';
    this.pnInstance?.on('notification', (res) => {
      if (res.additionalData && deviceToken)
        try {
          url = isAndroid()
            ? res.additionalData.extra.ns_web_url ||
              res.additionalData['pinpoint.url']
            : res.additionalData.ns_web_url ||
              res.additionalData.data.pinpoint.deeplink;
        } catch (error) {
          url = '';
        }
      if (url) {
        window.location.assign(url);
      }
    });
    this.pnInstance?.on('error', (error) => {
      window.console.log('Push Notification ', error);
    });
  }
}

export class CordovaPushNotificationNoopPlugin
  implements PushNotificationPlugin {
  hasPermission(): Promise<boolean> {
    return Promise.reject('push_notification.not_available');
  }

  init(): void {
    return;
  }

  getDeviceToken(): Promise<string> {
    return Promise.reject('push_notification.not_available');
  }

  registerNotificationListener(): void {
    return;
  }
}
